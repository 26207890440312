import Layout from "components/Layout";
import Page from "components/Page";
import SEO from "components/Seo";
import React from "react";

function NotFoundPage() {
  return (
    <Layout>
      <SEO title="404: Page not found" />
      <Page
        data={{
          heroSectionHeading: "404: Page not found",
          heroSectionImage: {
            fluid: {
              src:
                "//images.ctfassets.net/gnax899ipmuy/4HNXXIwV8kUgn4fPUroz1O/478618ed48bfdad9b681d8c4b6a02a89/about-1.jpg?w=800&q=50",
            },
          },
        }}
      />
    </Layout>
  );
}

export default NotFoundPage;
